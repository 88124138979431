@media (max-width: 768px) {
    .image {
      max-width: 100%;
      height: auto;
    }
  
    .right-arrow,
    .left-arrow {
      font-size: 1.5rem; /* Further reduce the font size */
    }
  }

  @media (min-width: 768px) {
    .image {
      max-width: 80%; /* Adjust the maximum width for desktop screens */
      max-height: 60vh;
    }
  
    .right-arrow,
    .left-arrow {
      font-size: 2.5rem; /* Increase the font size for desktop screens if needed */
    }
  }

.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }


  .right-arrow {
    position: absolute;
    top: 50%;
    right: 16px; /* Adjust the right distance */
    font-size: 2rem; /* Decrease the font size for smaller screens */
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 16px; /* Adjust the left distance */
    font-size: 2rem; /* Decrease the font size for smaller screens */
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }

.slide{
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}