


  .educationHub {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    text-align: justify;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    /* color: #1A1A1A; */

  }

  .author-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .author-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .author-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .byline {
    font-size: 18px;
  }

  .published-date {
    font-size: 14px;
    color: #757575;
  }




  .question {
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
  }

  .answer {
    font-size: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }

  a:link {
    color: #D3D3D3;
  }

  a:visited {
    color: #D3D3D3;
  }

  body {

    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  section {
    padding: 20px;
  }

  footer {
    background-color: #212121;
    color: white;
    text-align: center;
    padding: 10px;
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 100px 0 30 px;
    border-top-left-radius: 125;
    font-size: 13px;
    line-height: 20px;
    background: linear-gradient(to right, #00093c, #2d0b00);
    color: #fff;
  }

  .row {

    width: 85%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

  }

  .col {

    flex-basis: 25%;
    padding: 10px;
  }

  .Navbartop a:hover {


    background-color: #F5F5F5;

  }

  .Navbartop {

    background-color: #FFFFFF;
    padding: 25px 35px 25px 35px;
    text-align: center;


  }

  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }



  .dot {
    cursor: pointer;
    height: 13px;
    width: 13px;
    margin: 0 2px;
  }

  .Navbartop a {

    color: black;
    padding: 25px 35px 25px 35px;
    font-size: 17px;
    text-decoration: none;

  }

  header {
    background-color: white;
    color: white;
    padding: 20px;
  }

  header {
    font-size: 50px;
    text-align: center;
    font-family: freight-sans-pro;
    color: black;
    ;
  }

  h1 {

    margin-right: 0.67em;
    margin-left: 0.67em;
    margin-bottom: 0.67em;
    margin-top: 0.67em;



  }