h1 {
    text-align: center;
    font-size: 24px;
  }
  
  .countdown-timer {
    width: 90%; /* Use percentage for responsiveness */
    max-width: 600px; /* Limit maximum width */
    margin: 3rem auto; /* Center horizontally and provide vertical spacing */
  
    padding: 1rem; /* Add padding to the timer container */
    border: 2px solid navy;
    font-family: "Arial";
    font-size: 30px;
  }
  
  .countdown-timer span {
    margin-left: 4px; /* Adjust spacing between timer elements */
    margin-right: 4px;
  }
  
  .timer-container {
    display: flex; /* Display timer items in a row */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    flex-wrap: wrap; /* Allow items to wrap to the next line if necessary */
  }
  
  .timer-item {
    display: flex; /* Display each timer item (e.g., days, hours) as a row */
    flex-direction: column; /* Stack text and numbers vertically */
    align-items: center; /* Center text and numbers horizontally */
    margin: 0 8px; /* Add space between timer items */
    flex-basis: calc(25% - 16px); /* Adjust the width of each timer item */
    text-align: center; /* Center align text */
  }
  
  .two-numbers {
    width: 2ch;
  }
  