.boxes {
  background-color: #fff;
  display: grid;
  margin: auto;
  padding: 0rem;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.boxes {
  display: grid;
  column-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
}

.box {
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.box img {
  height: 70px;
}

.box h3 {
  color: black;
  margin: 10px 0;
  font-size: 15px;
}
