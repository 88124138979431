/* Podcasts.css */

.podcast-container {
    background-color: rgb(246, 246, 246);
    padding: 20px;
    text-align: center;
  }
  
  .podcast-title {
    padding-top: 25px;
    font-size: 1.5rem;
  }
  
  .podcast-iframe {
    margin-top: 20px;
    border-radius: 12px;
    overflow: hidden;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .podcast-iframe {
      height: auto;
    }
  }
  