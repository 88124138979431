/* ContactUs.css */

/* Styles for form container */
form {
    width: 100%;
    max-width: 600px; /* increased max-width */
    margin: 0 auto;
    padding: 20px;
}

/* Common input styles */
input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

/* Input focus styles */
input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    border-color: #3498db;
}

/* Button styles */
button {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px 0;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #3498db;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

button:hover,
button:active {
    background-color: #2980b9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Thank you message styles */
.text-2xl {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.text-md {
    font-size: 1.2rem;
    color: #555;
}

/* Increase the height of the textarea */
textarea {
    height: 200px;
}
